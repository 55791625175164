import { ChakraProps, chakra, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import {
	InlineAssetFragment,
	InlineAssetGalleryFragment,
} from "src/__generated__/cms-schema.codegen";
import { DatoAsset } from "src/components/Dato/DatoAsset";
import { VideoPlayerTrigger } from "../VideoPlayer/VideoPlayerTrigger";

type InlineAssetProps = InlineAssetFragment;

type InlineAssetGalleryProps = InlineAssetGalleryFragment;

export const InlineAssetGallery: React.FC<InlineAssetGalleryProps> = ({
	id,
	assets,
}) => {
	const styles = useMultiStyleConfig("InlineAsset");

	return (
		<chakra.div __css={styles.galleryWrapper}>
			{assets.map((asset) => (
				<chakra.div key={asset.url} __css={styles.galleryItem}>
					<InlineAsset
						__typename="InlineAssetRecord"
						id={id}
						limitWidth={null}
						asset={asset}
						sx={styles.galleryItemInner}
					/>
				</chakra.div>
			))}
		</chakra.div>
	);
};

export const InlineAsset: React.FC<InlineAssetProps & ChakraProps> = ({
	asset,
	limitWidth,
	...rest
}) => {
	const styles = useMultiStyleConfig("InlineAsset");

	return (
		<chakra.div
			data-structured-text-block={
				asset.video ? "inline-asset-video" : "inline-asset"
			}
			__css={styles.wrapper}
			{...rest}
		>
			{asset.video ? (
				<VideoPlayerTrigger
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
					name={asset.alt ?? asset.basename ?? undefined}
					autoPosterSrc={asset.video.thumbnailUrl}
					videoSrc={asset.video.streamingUrl}
				/>
			) : (
				<DatoAsset
					data={asset}
					alt={asset.alt ?? undefined}
					svgProps={{
						display: "block",
						margin: "auto",
						height: asset.height ?? undefined,
					}}
					datoImageProps={{
						style: {
							margin: "0 auto",
						},
					}}
					style={{
						margin: "auto",
						maxWidth: limitWidth ?? "100%",
					}}
				/>
			)}
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
