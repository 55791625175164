import { isStructuredText as isStructuredTextDato } from "datocms-structured-text-utils";
import React, { useMemo } from "react";
import {
	StructuredText as StructuredTextOrig,
	StructuredTextGraphQlResponse,
} from "react-datocms";
import { groupArray, truthy } from "src/lib/utils";
import { BlockType, renderBlocks } from "./renderBlocks";
import {
	HeadingRenderer,
	ParagraphRenderer,
	UnorderedListRenderer,
} from "./renderers";
import { HighlightRule } from "./rules";

export type StructuredTextData = StructuredTextGraphQlResponse<BlockType>;

export type StructuredTextProps = {
	data: StructuredTextData;
};

export const isStructuredText = (a: any): a is StructuredTextData =>
	isStructuredTextDato(a);

export const StructuredText: React.FC<StructuredTextProps> = ({ data }) => {
	return (
		<StructuredTextOrig
			data={data}
			customNodeRules={[
				HeadingRenderer,
				ParagraphRenderer,
				UnorderedListRenderer,
			]}
			renderBlock={renderBlocks}
			customMarkRules={[HighlightRule]}
		/>
	);
};

/**
 * The editor experience of nested blocks in Dato is suboptimal. In some cases we just
 * want to have blocks beneath each other in the CMS, but in the frontend we need to group
 * them by type for rendering (e.g. in the Offer section).
 *
 * Let's say you have [<p>, <p>, <h2>, <MyBlock>, <MyBlock>, <p>], this will make it:
 * [[<p>, <p>], [<h2>], [<MyBlock>, <MyBlock>], [<p>]]
 *
 * @param structuredText StructuredTextGraphQlResponse<T>
 * @returns Array<StructuredTextGraphQlResponse>
 */

type StructuredTextGroup = StructuredTextData & {
	key: string;
	type: string;
};

const groupStructuredTextByBlocks = (
	structuredText: StructuredTextData | undefined,
	blockTypes: Array<string> = [],
): Array<StructuredTextGroup> => {
	if (!structuredText) {
		return [];
	}

	return (
		// group each block…
		groupArray(
			// … that is not an empty paragraph type …
			structuredText.value.document.children.filter((child) => {
				if (
					child.type === "paragraph" &&
					child.children.length === 1 &&
					child.children[0]?.type === "span" &&
					child.children[0].value === ""
				) {
					return false;
				}

				return true;
			}),
			// … by type …
			(prev, curr) =>
				curr.type === "block"
					? prev.type !== "block"
					: prev.type === "block",
		)
			// … and reassemble the structured text ast
			.map((children, index): StructuredTextGroup | false => {
				const indexedType = blockTypes[index];
				const type = indexedType ? indexedType : "block";

				const key = [index + 1, type].join("-");

				// add a key and type to the object for later use
				return {
					key,
					type,
					...structuredText,
					value: {
						...structuredText.value,
						document: {
							...structuredText.value.document,
							children,
						},
					},
				};
			})
			.filter(truthy)
	);
};

export const useGroupedStructuredText = (
	...params: Parameters<typeof groupStructuredTextByBlocks>
) => useMemo(() => groupStructuredTextByBlocks(...params), [params]);

// 🔬 TBD: Please evaluate
