import {
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	VisuallyHidden,
} from "@chakra-ui/react";
import { ReactNode, useMemo } from "react";
import { URLModal as ReactURLModal, closeModal } from "react-url-modal";
import { useFeatureFlag } from "src/lib/featureFlags";
import { useSiteSettings } from "src/lib/hooks";
import { removeKeyFromObjectByList } from "src/lib/utils";
import { useHasLoginToken } from "src/queries/emil/account";
import { modalRoutes, portalRouteNames } from "./modalRoutes";
import { Modal } from "../Modal";
import { NoSSR } from "../NoSSR/NoSSR";

const ModalWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<NoSSR>
			<Modal isOpen={true} onClose={closeModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader />
					<VisuallyHidden>
						<ModalCloseButton />
					</VisuallyHidden>
					<ModalBody>{children}</ModalBody>
					<ModalCloseButton data-testid="close-modal" />
				</ModalContent>
			</Modal>
		</NoSSR>
	);
};

export const CustomURLModal: React.FC = () => {
	const {
		brandConfig: { hasPortal },
	} = useSiteSettings();
	const hasLoginToken = useHasLoginToken();
	const showCreateClaim = useFeatureFlag("portal-create-claim");

	const filteredModalRoutes = useMemo(() => {
		let filterOut: Array<string> = [];

		if (!hasLoginToken) {
			filterOut = [...filterOut, ...portalRouteNames];
		}

		if (!showCreateClaim) {
			filterOut = [...filterOut, "CreateClaim"];
		}

		if (!hasPortal) {
			filterOut = [...filterOut, "Login"];
		}

		return removeKeyFromObjectByList(modalRoutes, filterOut);
	}, [hasPortal, hasLoginToken, showCreateClaim]);

	return (
		<ReactURLModal
			Wrapper={ModalWrapper}
			adapter="nextjs"
			modals={filteredModalRoutes as Record<string, any>}
		/>
	);
};

// 🔬 TBD: Please evaluate
