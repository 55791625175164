import { chakra } from "@chakra-ui/react";
import { StructuredTextPropTypes } from "react-datocms";
import {
	InlineLinkButtonFragment,
	InlineModalButtonFragment,
	OfferSingleBlockFragment,
	PersonaFactSingleBlockFragment,
	ProductSingleBlockFragment,
	InlineResponsiveSvgFragment,
	InlineAssetFragment,
	InlineAssetGalleryRecord,
} from "src/__generated__/cms-schema.codegen";
import { Product } from "src/components/Product/Product";
import { Offer } from "src/sections/Offers/Offer";
import { PersonaFact } from "src/sections/Persona/PersonaFact";
import { isStructuredText } from "./StructuredText";
import { CMSLinkButton } from "../CMSButton/CMSLinkButton";
import { CMSModalButton } from "../CMSButton/CMSModalButton";
import { InlineAsset, InlineAssetGallery } from "../InlineAsset/InlineAsset";
import { ResponsiveSvg } from "../ResponsiveSvg/ResponsiveSvg";

export type BlockType =
	| OfferSingleBlockFragment
	| PersonaFactSingleBlockFragment
	| InlineLinkButtonFragment
	| InlineModalButtonFragment
	| ProductSingleBlockFragment
	| InlineAssetFragment
	| InlineAssetGalleryRecord
	| InlineResponsiveSvgFragment;

export const renderBlocks: StructuredTextPropTypes<BlockType>["renderBlock"] =
	// eslint-disable-next-line consistent-return
	({ record }) => {
		switch (record.__typename) {
			case "OfferSingleRecord":
				return (
					<Offer
						title={record.title}
						text={record.text ?? undefined}
						icon={record.icon}
						link={record.link}
						data-structured-text-block="offer"
					/>
				);

			case "PersonaFactRecord":
				return (
					<PersonaFact
						name={record.name}
						value={record.value}
						data-structured-text-block="persona-fact"
					/>
				);

			case "InlineLinkButtonRecord":
				return (
					<chakra.div data-structured-text-block="link-button">
						<CMSLinkButton {...record} />
					</chakra.div>
				);

			case "InlineModalButtonRecord":
				return (
					<chakra.div data-structured-text-block="modal-button">
						<CMSModalButton {...record} />
					</chakra.div>
				);

			case "ProductRecord":
				return isStructuredText(record.description) ? (
					<Product
						icon={record.icon}
						title={record.title}
						startingPrice={record.startingPrice ?? 0}
						priceDescription={record.priceDescription}
						description={record.description}
						headerAlignment={record.headerAlignment}
						data-structured-text-block="product"
					/>
				) : null;

			case "InlineAssetRecord":
				return <InlineAsset {...record} />;

			case "InlineAssetGalleryRecord":
				return <InlineAssetGallery {...record} />;

			case "ResponsiveSvgRecord":
				return <ResponsiveSvg my={{ base: 8, lg: 16 }} data={record} />;
		}
	};

// 🔬 TBD: Please evaluate
