import { AccountsApi } from "@emilgroup/account-sdk";
import { AuthserviceApi as BrokerAuthApi } from "@emilgroup/auth-sdk";
import { ClaimsApi } from "@emilgroup/claim-sdk";
import {
	CustomersApi,
	PoliciesApi,
	AuthenticationApi,
	InvitesApi,
	DocumentsApi as CustomerDocumentsApi,
} from "@emilgroup/customer-sdk";
import { DocumentsApi } from "@emilgroup/document-sdk";
import {
	LeadsApi,
	ProductsApi as BrokerProductsApi,
	PoliciesApi as BrokerPoliciesApi,
} from "@emilgroup/insurance-sdk";
import {
	Configuration,
	ProductsApi as PublicProductsApi,
	LeadsApi as PublicLeadsApi,
} from "@emilgroup/public-api-sdk";
import { CustomSchemaApi } from "@emilgroup/tenant-sdk";
import { PropsWithChildren, useState } from "react";
import { getEmilEnv } from "src/queries/emil/utils";
import { Newable } from "src/types";
import { createContext } from "../react";
import { isSSR } from "../ssr/utils";

type AnyEmilClass =
	| CustomersApi
	| PoliciesApi
	| AuthenticationApi
	| InvitesApi
	| CustomerDocumentsApi
	| LeadsApi
	| PublicLeadsApi
	| PublicProductsApi
	| BrokerProductsApi
	| BrokerAuthApi
	| BrokerPoliciesApi
	| AccountsApi
	| DocumentsApi
	| ClaimsApi
	| CustomSchemaApi;

export const tenantSlug = process.env.EMIL_TENANT_SLUG ?? "";

const initEmilClass = <T extends AnyEmilClass>(
	c: Newable<T>,
	options?: { skipAuth?: boolean } | undefined,
) => {
	const { skipAuth } = options ?? { skipAuth: false, env: undefined };

	if (isSSR) {
		return {} as unknown as T;
	}

	const instance = new c(
		skipAuth
			? new Configuration({
					accessToken: `Bearer ${tenantSlug}`,
				})
			: undefined,
	);

	instance.selectEnvironment(getEmilEnv());
	if (!skipAuth) {
		instance.loadTokenData();
	}

	return instance;
};

const initEmilApis = () => {
	return {
		customersApi: initEmilClass(CustomersApi),
		policiesApi: initEmilClass(PoliciesApi),
		authApi: initEmilClass(AuthenticationApi),
		invitesApi: initEmilClass(InvitesApi),
		customerDocumentsApi: initEmilClass(CustomerDocumentsApi),
		publicProductsApi: initEmilClass(PublicProductsApi, { skipAuth: true }),
		publicLeadsApi: initEmilClass(PublicLeadsApi, { skipAuth: true }),
		brokerAuthApi: initEmilClass(BrokerAuthApi),
		leadsApi: initEmilClass(LeadsApi),
		brokerProductsApi: initEmilClass(BrokerProductsApi),
		brokerPoliciesApi: initEmilClass(BrokerPoliciesApi),
		accountsApi: initEmilClass(AccountsApi),
		documentsApi: initEmilClass(DocumentsApi),
		claimsApi: initEmilClass(ClaimsApi),
		reset: initEmilApis,
		customSchemaApi: initEmilClass(CustomSchemaApi),
	};
};

type EmilContextType = ReturnType<typeof initEmilApis>;

const [EmilContext, useEmilContext] =
	createContext<EmilContextType>("EmilContext");

export const EmilProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [value] = useState(initEmilApis);

	return (
		<EmilContext.Provider value={value}>{children}</EmilContext.Provider>
	);
};

export const useCustomersApi = () => useEmilContext().customersApi;
export const useCustomerDocumentsApi = () =>
	useEmilContext().customerDocumentsApi;
export const useAuthApi = () => useEmilContext().authApi;
export const useInvitesApi = () => useEmilContext().invitesApi;
export const usePoliciesApi = () => useEmilContext().policiesApi;
export const useBrokerAuthApi = () => useEmilContext().brokerAuthApi;
export const useLeadsApi = () => useEmilContext().leadsApi;
export const useBrokerProductsApi = () => useEmilContext().brokerProductsApi;
export const useBrokerPoliciesApi = () => useEmilContext().brokerPoliciesApi;
export const usePublicProductsApi = () => useEmilContext().publicProductsApi;
export const usePublicLeadsApi = () => useEmilContext().publicLeadsApi;
export const useAccountsApi = () => useEmilContext().accountsApi;
export const useDocumentsApi = () => useEmilContext().documentsApi;
export const useClaimsApi = () => useEmilContext().claimsApi;
export const useCustomSchemaApi = () => useEmilContext().customSchemaApi;

export { useEmilContext };

// 🔬 e2e
