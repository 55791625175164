import { Flex, useMultiStyleConfig } from "@chakra-ui/react";
import { SkipNavContent, SkipNavLink } from "@chakra-ui/skip-nav";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import React from "react";
import { useTranslatedString } from "src/i18n/i18n";
import { useSiteSettings } from "src/lib/hooks";
import { setHighlightColor } from "src/styles/highlightColor";
import { NoScript } from "../NoScript";
import { CustomURLModal } from "../URLModal/URLModal";

type Props = {
	children: React.ReactNode;
	header: React.ReactNode;
	footer: React.ReactNode;
	variant?: "errorPage";
	highlightColor: string | null;
};

const SKIP_NAV_ID = "main-content";

const Layout: React.FC<Props> = ({
	children,
	header,
	footer,
	variant,
	highlightColor,
}) => {
	const router = useRouter();
	const t = useTranslatedString();
	const styles = useMultiStyleConfig("Layout", { variant });
	const {
		brandConfig: { slug: brandSlug, gtmId, ucId },
		faviconCacheBuster,
	} = useSiteSettings();
	const showConsentManager = !router.route.includes("dato-plugin");
	const isProduction = !process.env.FEX_DEBUG_MODE;

	return (
		<Flex
			direction="column"
			minHeight="100vh"
			data-brand={brandSlug}
			{...setHighlightColor(highlightColor ?? undefined)}
		>
			{!isProduction && ucId && (
				<noscript
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: `<!-- ${JSON.stringify({
							brandSlug,
							gtmId,
						})} -->`,
					}}
				/>
			)}

			{showConsentManager && ucId && (
				<Script
					id="usercentrics-cmp"
					src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
					data-settings-id={ucId}
				/>
			)}

			{gtmId && isProduction && (
				<Script
					id="gtag-base"
					dangerouslySetInnerHTML={{
						__html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${gtmId}');
          `,
					}}
				/>
			)}

			<Head>
				<link
					rel="icon"
					href={`/favicon-48.png?v=${faviconCacheBuster}`}
					type="image/png"
				/>
				<link
					rel="icon"
					href={`/favicon.svg?v=${faviconCacheBuster}`}
					type="image/svg+xml"
				/>
				<link
					rel="apple-touch-icon"
					href={`/favicon-apple-touch.png?v=${faviconCacheBuster}`}
				/>
				<link
					rel="manifest"
					href={`/manifest.json?v=${faviconCacheBuster}`}
				/>
			</Head>

			<SkipNavLink
				data-datocms-noindex
				data-axe-ignore
				zIndex="skipLink"
				id={SKIP_NAV_ID}
			>
				{t("skipLink")}
			</SkipNavLink>
			{header}
			<SkipNavContent
				id={SKIP_NAV_ID}
				aria-label={t("pageContent")}
				as="main"
				sx={styles.main}
			>
				<NoScript />
				{children}
			</SkipNavContent>
			{footer}
			<CustomURLModal />
		</Flex>
	);
};

export { Layout };

// 🔬 TBD: Please evaluate
