import { chakra, ChakraProps } from "@chakra-ui/react";
import Hls from "hls.js";
import { VideoHTMLAttributes, useEffect, useRef } from "react";

export type HlsPlayerProps = ChakraProps &
	VideoHTMLAttributes<HTMLVideoElement> & { src: string };

export const HlsPlayer = ({ src, controls, ...props }: HlsPlayerProps) => {
	const videoRef = useRef<HTMLVideoElement>(null);

	const isBackground = !controls;

	useEffect(() => {
		const video = videoRef.current;

		if (!video) {
			return;
		}

		// Browsers that don't support MSE will do nothing here...
		// https://developer.mozilla.org/en-US/docs/Web/API/Media_Source_Extensions_API
		// https://caniuse.com/mediasource

		// This will run in safari, where HLS is supported natively
		// https://github.com/video-dev/hls.js/#embedding-hlsjs
		if (video.canPlayType("application/vnd.apple.mpegurl")) {
			video.src = src;

			// This will run in all other modern browsers
		} else if (Hls.isSupported()) {
			const hls = new Hls();

			hls.loadSource(src);
			hls.attachMedia(video);
		}
	}, [src, videoRef]);

	return (
		<chakra.video
			tabIndex={isBackground ? -1 : undefined}
			ref={videoRef}
			src={src}
			controls={controls}
			{...props}
		/>
	);
};

// 🔬 TBD: Please evaluate
